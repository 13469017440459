import reducer from "./reducers";
import thunk from "redux-thunk";
import { createStore, applyMiddleware } from "redux";
import { persistStore, persistCombineReducers } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["userProfile", "orders", "cart", "myOrders", "contractorOrders", "wallet"],
};

const persistedReducer = persistCombineReducers(persistConfig, reducer);

export default () => {
  const store = createStore(persistedReducer, applyMiddleware(thunk));
  let persistor = persistStore(store);

  return { store, persistor };
};
