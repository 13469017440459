import cartReducer from "./cartReducer";
import navReducer from "./navReducer";
import userProfileReducer from "./userProfileReducer";
import orderReducer from "./orderReducers";
import contractorOrderReducer from "./contactorOrderReducers";
import myOrderReducer from "./myOrderReducer";
import walletReducer from "./walletBalanceReducer";

const reducers = {
  cart: cartReducer,
  nav: navReducer,
  userProfile: userProfileReducer,
  orders: orderReducer,
  myOrders: myOrderReducer,
  contractorOrders: contractorOrderReducer,
  wallet: walletReducer,
};

export default reducers;
