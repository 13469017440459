import React from "react";

const SignUp = React.lazy(() => import("./App/pages/Authentication/SignUp"));
const SignIn = React.lazy(() => import("./App/pages/Authentication/SignIn"));
const ForgetPassword = React.lazy(() =>
  import("./App/pages/Authentication/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("./App/pages/Authentication/ResetPassword")
);
const VerifyEmail = React.lazy(() => import("./App/pages/Authentication/VerifyEmail"));
const ErrorPage = React.lazy(() => import("./App/pages/Authentication/ErrorPage"));
const Landing = React.lazy(() => import('./Landing'))


const route = [
  {
    path: "/",
    exact: true,
    name: "Landing",
    component: Landing,
  },
  { path: "/register", exact: true, name: "Signup", component: SignUp },
  { path: "/login", exact: true, name: "Signin", component: SignIn },
  {
    path: "/forget-password/:type",
    exact: true,
    name: "Forget Password",
    component: ForgetPassword,
  },
  {
    path: "/reset-password/:key",
    exact: true,
    name: "Reset Password",
    component: ResetPassword,
  },
  {
    path: "/confirmUser/:key",
    exact: true,
    name: "Verify Email",
    component: VerifyEmail,
  },
  { path: "/404", exact: true, name: "Page not found", component: ErrorPage },
];

export default route;
