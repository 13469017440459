export const COLLAPSE_MENU = "COLLAPSE_MENU";
export const COLLAPSE_TOGGLE = "COLLAPSE_TOGGLE";
export const FULL_SCREEN = "FULL_SCREEN";
export const FULL_SCREEN_EXIT = "FULL_SCREEN_EXIT";
export const CHANGE_LAYOUT = "CHANGE_LAYOUT";
export const NAV_CONTENT_LEAVE = "NAV_CONTENT_LEAVE";
export const NAV_COLLAPSE_LEAVE = "NAV_COLLAPSE_LEAVE";
export const SAVE_USER_PROFILE = "SAVE_USER_PROFILE";
export const SAVE_ALL_ORDERS = "SAVE_ALL_ORDERS";
export const ADD_TO_CART = "ADD_TO_CART";
export const CLEAR_CART = "CLEAR_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const UPDATE_ITEM_QTY = "UPDATE_ITEM_QTY";
export const SAVE_ALL_CONTRACTOR_ORDERS = "SAVE_ALL_CONTRACTOR_ORDERS";
export const SAVE_MY_ORDERS = "SAVE_MY_ORDERS";
export const SAVE_WALLET_BALANCE = "SAVE_WALLET_BALANCE";
