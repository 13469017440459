const initialState = {
  count: 0,
  items: [],
  total: 0,
};

const calculateCartToTal = (cartItems) => {
  const total = cartItems
    .map((x) => {
      const measuremIsPresent = x.measurement && x.measurement.length !== 0;
      const itemAmount = measuremIsPresent ? x.measurement[0].minimum_price : x.amount;
      const itemQuantity = measuremIsPresent ? x.measurement[0].quantity : x.quantity;

      return itemAmount * itemQuantity;
    })
    .reduce((acc, cur) => acc + cur, 0);

  return total;
};

const calculateCartTotalCount = (cartItems) =>
  cartItems
    .map((x) => {
      if (x.measurement && x.measurement.length !== 0) {
        return x.measurement[0].quantity;
      }

      return x.quantity;
    })
    .reduce((acc, cv) => acc + cv, 0);

const cartReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ADD_TO_CART":
      let item = [];
      const cart = { ...state };
      cart.count += 1;

      const ind = cart.items.findIndex((item) => item.id === action.payload.id);

      if (ind !== -1) {
        if (cart.items[ind].measurement && cart.items[ind].measurement.length !== 0) {
          cart.items[ind].measurement[0].quantity += 1;
        } else {
          cart.items[ind].quantity += 1;
        }

        const total = calculateCartToTal(cart.items);
        return {
          ...state,
          count: cart.count,
          items: [...cart.items],
          total: total,
        };
      }

      if (action.payload.measurement && action.payload.measurement.length !== 0) {
        const payload = action.payload;

        payload.measurement[0].quantity = 1;
        item = [...state.items, { ...payload }];
      } else {
        item = [...state.items, { ...action.payload, quantity: 1 }];
      }

      const total = calculateCartToTal(item);

      return {
        ...state,
        count: cart.count,
        items: [...item],
        total: total,
      };

    case "UPDATE_ITEM_QTY": {
      const cart = { ...state };
      const ind = cart.items.findIndex((item) => item.id === action.payload.itemID);

      if (ind !== -1) {
        if (cart.items[ind].measurement && cart.items[ind].measurement.length !== 0) {
          cart.items[ind].measurement[0].quantity = +action.payload.qty;
        } else {
          cart.items[ind].quantity = +action.payload.qty;
        }
      }

      const total = calculateCartToTal(cart.items);

      // Get new cart count
      const count = calculateCartTotalCount(cart.items);

      return {
        ...state,
        count: count,
        items: [...cart.items],
        total: total,
      };
    }

    case "REMOVE_FROM_CART": {
      const cart = { ...state };
      const newItems = cart.items.filter((item) => item.id !== action.payload);

      cart.items = newItems;
      const total = calculateCartToTal(cart.items);

      // Get new cart count
      const count = calculateCartTotalCount(cart.items);

      return {
        ...state,
        count: count,
        items: [...cart.items],
        total: total,
      };
    }

    case "CLEAR_CART": {
      return {
        ...initialState,
      };
    }

    default:
      return state;
  }
};

export default cartReducer;
