const initialState = {
  fetched: false,
  data: [],
};

const contractorOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_ALL_CONTRACTOR_ORDERS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default contractorOrderReducer;
