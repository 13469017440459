const initialState = {
  fetched: false,
  data: [],
};

const myOrderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SAVE_MY_ORDERS":
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};

export default myOrderReducer;
